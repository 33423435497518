import React, { useEffect, useState } from "react";
import Layout from "../_components/Layout/Layout";
import Banner from "../_components/Banner/Banner";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import { goto } from "../_utils/Misc";
import Qr from "../_utils/Qr";
import RewardsCarousel from "../_components/RewardsCarousel/RewardsCarousel";
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn";

const RewardLander = () => {
    const [isIOS, setIsIOS] = useState(true);


    function iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            || ((navigator.userAgent.includes("Mac")||(navigator.userAgent.includes("iPhone"))) && "ontouchend" in document);
    }

    useEffect(() => {
        setIsIOS(iOS());
        if (!Qr.hasStoredParams()) {
            goto("/");
        }
    }, []);

    return (
        <Layout title="Welcome" hideSubNav={true}>
            <div className="video-wrapper">

                {isIOS?
                    (<div className="main-image-cover-background">
                        <img src="/images/glide/main-image-bg.jpg" alt="ios main bg"/>
                    </div>):
                    (<video className="video-background" autoPlay="autoplay" playsInline muted loop>
                        <source src="/video.mp4" type="video/mp4"/>
                    </video>)}


                <div className="title-wrapper">
                    <MainTitle>
                        Congratulations!
                    </MainTitle>

                    <PageSummary>Click below to claim your Christmas gift from us. Everybody wins something!</PageSummary>
                </div>

                <div class="video-overlay"></div>
            </div>
            <ClaimRewardBtn />

            <Banner
                style="style_2"
                image="/images/glide/Jo-Malone-Landing-page.jpg"
                text="GIFTS FROM JO MALONE"
            />

            <Banner
                style="style_2"
                image="https://assets.xela.co/glb/51c70cde-85c7-4008-ab0d-81bca580c8fe.jpg"
                text="TICKETS TO TASTE OF LONDON"
            />

            <Banner
                style="style_2"
                image="/images/glide/Tickets-to-glide.jpg"
                text="£250 Voucher from No 29 Power Station West"
            />

            <RewardsCarousel></RewardsCarousel>

            <ClaimRewardBtn />
        </Layout>
    );
};

export default RewardLander;
